import Swiper from "swiper";

const mq = window.matchMedia("(min-width: 1280px)");

initSwiper(mq);

// eslint-disable-next-line
mq.addListener(initSwiper as any);

function initSwiper(mq: MediaQueryList) {
  if (!mq.matches) {
    new Swiper(".swiper-container", {
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      slidesPerView: 1.35,
      spaceBetween: 15,
      centeredSlides: true,
      breakpoints: {
        768: {
          slidesPerView: 2.25,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 40,
          centeredSlides: false,
        },
      },
    });
  }
}

export default initSwiper;
